<template lang="pug">
  v-container.gastroprice-content.d-flex.flex-column(fluid)
    gp-filter-top(@getData="getRde")
    .block-content.row
      v-container.table-data.ma-5.pa-0(fluid)
        .table-title.pa-4.pb-2.d-flex.align-center
          p.mb-0.pt-3 #[translate RDE - Seasonal Discrimination Ratio]
          .block-legend.ml-auto
            .item.d-flex.align-center
              span.itemBoxBgColorTable.up &nbsp;
              p.mb-1.ml-1 #[translate Possibilities of raising prices on the index (ICD)]
            .item.d-flex.align-center
              span.itemBoxBgColorTable.ligthUp &nbsp;
              p.mb-1.ml-1 #[translate Possibility of adjusting a price increase in a timely manner.]
            .item.d-flex.align-center
              span.itemBoxBgColorTable.noUp &nbsp;
              p.mb-0.ml-1 #[translate Do not raise prices]
        .table-content
          .header.d-flex
            v-col.col-population.d-flex.align-end(cols="2")
              p #[translate POPULATION]
            v-col.col-month.row.pa-0.ma-0(cols="10")
              v-col.text-center.title-available(cols="12")
                p.mb-0.mt-4 #[translate AVAILABLE RENT]
              v-col.p-relative.border-rigth(cols="1")
                span #[translate January]
                .block-order
                  svg(@click="order('-', 1)" :class="{'active' : orderActive === 'order-1'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 1)" :class="{'active' : orderActive === 'order1'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate February]
                .block-order
                  svg(@click="order('-', 2)" :class="{'active' : orderActive === 'order-2'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 2)" :class="{'active' : orderActive === 'order2'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate March]
                .block-order
                  svg(@click="order('-', 3)" :class="{'active' : orderActive === 'order-3'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 3)" :class="{'active' : orderActive === 'order3'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate April]
                .block-order
                  svg(@click="order('-', 4)" :class="{'active' : orderActive === 'order-4'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 4)" :class="{'active' : orderActive === 'order4'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate May]
                .block-order
                  svg(@click="order('-', 5)" :class="{'active' : orderActive === 'order-5'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 5)" :class="{'active' : orderActive === 'order5'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate June]
                .block-order
                  svg(@click="order('-', 6)" :class="{'active' : orderActive === 'order-6'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 6)" :class="{'active' : orderActive === 'order6'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate July]
                .block-order
                  svg(@click="order('-', 7)" :class="{'active' : orderActive === 'order-7'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 7)" :class="{'active' : orderActive === 'order7'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate August]
                .block-order
                  svg(@click="order('-', 8)" :class="{'active' : orderActive === 'order-8'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 8)" :class="{'active' : orderActive === 'order8'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate Sept.]
                .block-order
                  svg(@click="order('-', 9)" :class="{'active' : orderActive === 'order-9'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 9)" :class="{'active' : orderActive === 'order9'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate October]
                .block-order
                  svg(@click="order('-', 10)" :class="{'active' : orderActive === 'order-10'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 10)" :class="{'active' : orderActive === 'order10'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative.border-rigth(cols="1")
                span #[translate Nov.]
                .block-order
                  svg(@click="order('-', 11)" :class="{'active' : orderActive === 'order-11'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 11)" :class="{'active' : orderActive === 'order11'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
              v-col.p-relative(cols="1")
                span #[translate Dec.]
                .block-order
                  svg(@click="order('-', 12)" :class="{'active' : orderActive === 'order-12'}" aria-hidden="true" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z")
                  svg.mt-n4(@click="order('', 12)" :class="{'active' : orderActive === 'order12'}" aria-hidden="true" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="")
                    path(fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z")
          .content-item.d-flex.align-center(v-for="(rde, index) in rdeData" :key="rde.id")
            v-col.col-population.d-flex.align-end(cols="2")
              p.mb-0 {{rde[0]}}
            v-col.col-month.row.pa-0.ma-0(cols="10")
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[1])")
                span(v-if="rde[1] !== null") {{rde[1] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[2])")
                span(v-if="rde[2] !== null") {{rde[2] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[3])")
                span(v-if="rde[3] !== null") {{rde[3] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[4])")
                span(v-if="rde[4] !== null") {{rde[4] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[5])")
                span(v-if="rde[5] !== null") {{rde[5] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[6])")
                span(v-if="rde[6] !== null") {{rde[6] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[7])")
                span(v-if="rde[7] !== null") {{rde[7] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[8])")
                span(v-if="rde[8] !== null") {{rde[8] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[9])")
                span(v-if="rde[9] !== null") {{rde[9] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[10])")
                span(v-if="rde[10] !== null") {{rde[10] | formatNumber}} %
              v-col.text-center.border-rigth(cols="1" :class="getProgressBarClass(rde[11])")
                span(v-if="rde[11] !== null") {{rde[11] | formatNumber}} %
              v-col.text-center(cols="1" :class="getProgressBarClass(rde[12])")
                span(v-if="rde[12] !== null") {{rde[12] | formatNumber}} %

</template>
<script>
import GpFilterTop from '@/components/dashboard/filter-top.vue'
import SrvDashboard from '@/services/dashboard.js'

export default {
  components: {GpFilterTop},
  name: 'rde',
  data() {
    return {
      dataQuery: [],
      rdeData: [],
      orderActive: '',
      img: {
        ico_data_tooltip : require('@/assets/img/ico_data_tooltip.png')
      },
      titleFilter: this.$gettext('Seasonal Discrimination Ratio')
    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
    this.$store.commit('TITLE_HEADER', this.titleFilter)
    this.$store.commit('HAS_MENU', '')
  },
  computed: {
  },
  methods: {
    getRde(data, ordering = ''){
      this.dataQuery = data
      SrvDashboard.getDashData('rde', data.brand, data.filters, ordering)
      .then(res => {
          if (res.status === 200) {
            this.rdeData = res.data.table.rows
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    order(ordering, col) {
      this.orderActive = 'order' + ordering + col
      var queryOrder = ordering + col
      this.getRde(this.dataQuery, queryOrder)
    },
    getProgressBarClass: function(param){
      if(param < 10){
        return 'bg-color-top';
      }else if(param >= 10 && param <=20){
        return 'bg-color-medium';
      }else{
        return 'bg-color-low';
      }
    },
  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .gastroprice-content{
    .block-legend{
      .itemBoxBgColorTable{
        display: inline-block;
        width: 12px;
        height: 12px;
        vertical-align: middle;
        margin-right: 2px;
        &.up{
          background-color:#1AAA55;
        }
        &.ligthUp{
          background-color:#FC9403;
        }
        &.noUp{
          background-color: #DB3B21;
        }
      }
      p{
        font-weight: normal;
        font-size: 13px;
      }
    }
    .table-content{
      .header{
        border-bottom: 1px solid #D9DFE8;
        height: unset;
        .col-population{
          border-right: 1px solid #9B9B9B;
          p{
            font-size: 14px;
            color:var(--v-gp_gray-base);
            font-weight: 500;
          }
        }
        .col-month{
          .title-available{
            font-size: 14px;
            font-weight: 500;
            color: var(--v-gp_gray-base);
            border-bottom: 1px solid #D9DFE8;
          }
          .border-rigth{
            border-right: 1px solid #D9DFE8;
          }
          span{
            font-size: 14px;
            font-weight: 500;
          }
          .block-order{
            width: 12px;
            position: absolute;
            right: 4px;
            top: 15px;
            color: #d7dae2;
            svg{
              display: block;
              cursor: pointer;
              &.active{
                color:var(--v-terciary-base)
              }
              &:hover{
                color:var(--v-terciary-base)
              }
            }
            
          }
        }
      }
      .content-item{
        height: 54px;
        border-bottom: 1px solid #D9DFE8;
        p{
          font-size: 14px;
        }
        .col-month{
          height: 100%;
          .border-rigth{
            border-right: 1px solid #D9DFE8;
          }
          span{
            font-size: 14px;
            font-weight: 500;
            color: #fff;
          }
          .bg-color-top{
            background-color: var(--v-bg_top-base);
          }
          .bg-color-medium{
            background-color: var(--v-bg_medium-base);
          }
          .bg-color-low{
            background-color: var(--v-bg_low-base);
          }
        }
      }
    }
  }
  

</style>